<template>
  <tr>
    <td>{{ coupon.coupon_uuid }}</td>
    <td>{{ coupon.external_coupon_id }}</td>
    <td>{{ this.money(coupon.amount) }}</td>
    <td>{{ this.money(coupon.recurly_id) }}</td>
    <td>{{ this.money(coupon.recurly_code) }}</td>
  </tr>
</template>

<script>
import { moneyMixin } from '@/components/mixins';

export default {
  name: 'CouponListItem',
  props: ['coupon'],
  mixins: [moneyMixin]
};
</script>
